<template>
  <div class="d-flex justify-content-between">
    <div class="screen-title">エネルギー契約一覧</div>
    <a-button type="primary" size="large" ghost @click="$router.push({ name: 'energy_contract.create' })">
      新規作成
    </a-button>
  </div>
  <div class="content">
    <a-form>
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="種別:">
            <a-select
                v-model:value="searchParams.energy_type_id"
                :disabled="isLoading"
                :fieldNames="{label: 'name', value: 'id'}"
                :options="energyTypes"/>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="24" class="position-relative">
        <a-col :span="5" v-if="this.$store.state.role.role === '管理者'">
          <a-form-item label="代理店">
            <a-select
                placeholder="代理店"
                v-model:value="searchParams.agency"
                :options="agencies"
                :disabled="isLoading"
                @change="filterCompanyByAgency"
                :fieldNames="{label: 'name', value: 'id'}"/>
          </a-form-item>
        </a-col>

        <a-col :span="5"
               v-if="!this.$store.state.role.role.includes('企業') && !this.$store.state.role.role.includes('施設')">
          <a-form-item label="企業:">
            <a-select
                v-model:value="searchParams.company"
                :disabled="isLoading"
                :fieldNames="{label: 'name', value: 'id'}"
                :options="companies"
                @change="filterSelectFacilityByCompany"/>
          </a-form-item>
        </a-col>

        <a-col :span="5" v-if="!this.$store.state.role.role.includes('施設')">
          <a-form-item label="施設:">
            <a-select
                v-model:value="searchParams.facility"
                :disabled="isLoading"
                :fieldNames="{label: 'name', value: 'id'}"
                @change="filterSelectBuilding"
                :options="facilities"/>
          </a-form-item>
        </a-col>

        <a-col :span="5">
          <a-form-item label="建屋:">
            <a-select
                v-model:value="searchParams.building"
                :disabled="isLoading"
                :fieldNames="{label: 'name', value: 'id'}"
                :options="buildings"/>
          </a-form-item>
        </a-col>

        <a-col :span="4" class="search-btn">
          <a-button type="primary" :loading="isLoading" @click="getList(1, {...searchParams})">
            <template #icon>
              <SearchOutlined/>
            </template>
            検索
          </a-button>
        </a-col>
      </a-row>

    </a-form>
    <a-divider/>

    <a-table
        :loading="isLoading"
        :dataSource="dataSource"
        :columns="tableColumns"
        :pagination="pagination"
        @change="handleTableChange">
      <template #bodyCell="{column, text, record}">
        <template v-if="column.dataIndex === 'type'">
          {{ record?.energy_type?.name }}
        </template>
        <template v-if="column.dataIndex === 'contract_company_name'">
          {{ record.contract_company_name !== null ? record.contract_company_name : record.electric_company?.name }}
        </template>
        <template v-if="column.dataIndex === 'company' && record.company_id">
          <router-link :to="{ name: 'company.edit', params: {companyId: record?.company?.id }}">
            {{ record?.company?.name }}
          </router-link>
        </template>
        <template v-if="column.dataIndex === 'facility' && record.facility_id">
          <router-link :to="{ name: 'facility.edit', params: {facilityId: record.facility_id }}">
            {{ record?.facility?.name }}
          </router-link>
        </template>
        <template v-if="column.dataIndex === 'building' && record?.building_id">
          <router-link :to="{ name: 'building.edit', params: {buildingId: record?.building_id }}">
            {{ record?.building?.name }}
          </router-link>
        </template>
        <template v-if="column.dataIndex === 'action'">
          <router-link :to="{ name: 'energy_contract.edit', params: {contractId: record.id }}">詳細
          </router-link>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
import {
  SearchOutlined
} from '@ant-design/icons-vue'
import {EnergyContractModel} from "@/model/energyContract"
import {CompanyModel} from "@/model/company"
import {FacilityModel} from "@/model/facility"
import {BuildingModel} from "@/model/building"
import {agency} from "@/model/agency"

const defaultSelectOption = {
  name: '全て',
  id: null
}
export default {
  components: {
    SearchOutlined
  },
  data() {
    const handleTableChange = async (pag, filters, sorter) => {
      this.isLoading = true
      await this.getList(pag.current, {...this.searchParams}, pag.pageSize)
      this.isLoading = false
    };

    return {
      isLoading: true,
      handleTableChange,
      tableColumns: [
        {
          title: '種別',
          dataIndex: 'type'
        },
        {
          title: '建屋',
          dataIndex: 'building',
          width: 200,
          ellipsis: true
        },
        {
          title: '契約先',
          dataIndex: 'contract_company_name',
          key: 'contract_company_name',
          width: 300,
          ellipsis: true
        },
        {
          title: 'CO2変換係数',
          dataIndex: 'co2_convert_coefficient',
          width: 200,
          key: 'co2_convert_coefficient'
        },
        {
          title: '操作',
          dataIndex: 'action'
        }
      ],
      dataSource: [],
      pagination: {},
      searchParams: {
        searchKey: null,
        energy_type_id: null,
        agency: null,
        company: null,
        facility: null,
        building: null
      },
      energyTypes: [],
      companies: [],
      facilities: [],
      buildings: [],
      agencies: [],
    }
  },
  async mounted() {
    this.energyTypes = [defaultSelectOption, ...await EnergyContractModel.getListEnergyType()]

    let user = await this.$store.dispatch('auth/me')

    if (this.$store.state.role.role === '管理者') {
      this.agencies = [defaultSelectOption, ...await agency.getAllAgencyNames()]
      this.companies = [defaultSelectOption, ...await CompanyModel.getAllCompanyNames()]
      this.facilities = [defaultSelectOption, ...await FacilityModel.getAllFacilityNames()]
    } else {
      this.searchParams.agency = user.agency_id
      this.searchParams.company = user.company_id
      this.searchParams.facility = user.facility_id
      this.companies = [defaultSelectOption, ...await CompanyModel.getCompanyByAgency(user.agency_id)]
      this.facilities = [defaultSelectOption, ...await FacilityModel.getFacilityByParent(user.agency_id, user.company_id)]
    }

    this.buildings = [defaultSelectOption, ...await BuildingModel.getListBuilding({
      agency_id: this.searchParams.agency ?? 0,
      company_id: this.searchParams.company ?? 0,
      facility_id: this.searchParams.facility ?? 0
    })]

    this.getList(1, this.searchParams)
  },
  methods: {
    getList(page, params = {
      searchKey: null,
      energy_type_id: null,
      agency: null,
      company: null,
      facility: null,
      building: null
    }, perPage = 10) {
      this.isLoading = true
      EnergyContractModel.list({
        page,
        perPage,
        searchKey: params.searchKey,
        energy_type_id: params.energy_type_id,
        agency: params.agency,
        company: params.company,
        facility: params.facility,
        building: params.building
      }).then(res => {
        this.dataSource = res.data
        this.pagination = {
          total: res.total,
          current: res.current_page,
          pageSize: res.per_page,
        }
        this.isLoading = false
      })
    },

    async filterCompanyByAgency() {
      this.searchParams.company = null
      this.searchParams.facility = null
      this.searchParams.building = null
      this.companies = [defaultSelectOption, ...await CompanyModel.getCompanyByAgency(this.searchParams.agency ?? 0)]
      this.facilities = [defaultSelectOption, ...await FacilityModel.getFacilityByParent(this.searchParams.agency ?? 0, this.searchParams.company ?? 0)]
      this.buildings = [defaultSelectOption, ...await BuildingModel.getListBuilding({
        agency_id: this.searchParams.agency ?? 0,
        company_id: this.searchParams.company ?? 0,
        facility_id: this.searchParams.facility ?? 0
      })]
    },

    async filterSelectFacilityByCompany() {
      this.searchParams.facility = null
      this.searchParams.building = null
      this.facilities = [defaultSelectOption, ...await FacilityModel.getFacilityByParent(this.searchParams.agency ?? 0, this.searchParams.company ?? 0)]
      this.buildings = [defaultSelectOption, ...await BuildingModel.getListBuilding({
        agency_id: this.searchParams.agency ?? 0,
        company_id: this.searchParams.company ?? 0,
        facility_id: this.searchParams.facility ?? 0
      })]
    },

    async filterSelectBuilding() {
      this.searchParams.building = null
      this.buildings = [defaultSelectOption, ...await BuildingModel.getListBuilding({
        agency_id: this.searchParams.agency ?? 0,
        company_id: this.searchParams.company ?? 0,
        facility_id: this.searchParams.facility ?? 0
      })]
    }
  }
}
</script>
